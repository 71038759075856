<template>
  <section id="ExperiencesMobile">
    <div class="mobile-overlay"></div>
    <div class="item">
      <h1>
        North Video Boards
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="northVideoBoards" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        South Video Board
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="southVideoBoard" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Ribbon Boards
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="ribbonBoards" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Marquee
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="marquee" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Media Mesh
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="mediaMesh" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item tours">
      <h1>
        Stadium Tours
        <div class="line"></div>
      </h1>
      <StadiumTours />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        PPK
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="ppk" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        IMAG
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="imag" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Raiderettes
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="raiderettes" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Rusher
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="rusher" />
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
    </div>
    <div class="item">
      <h1>
        Alumni
        <div class="line"></div>
      </h1>
      <ExperiencesCarousel :carouselImages="alumni" />
    </div>
  </section>
</template>

<script>
import ExperiencesCarousel from "@/components/global/ExperiencesCarousel";
import StadiumTours from "@/components/sections/Experiences/StadiumTours/StadiumTours";

export default {
  name: "ExperiencesMobile",
  components: {
    ExperiencesCarousel,
    StadiumTours,
  },
  data() {
    return {
      northVideoBoards: [
        {
          image: "Experiences/Branding/north-video-board-1.jpg",
          title: "North Video Board",
          description:
            "The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.",
        },
      ],
      southVideoBoard: [
        {
          image: "Experiences/Branding/south-video-board-2.jpg",
          title: "South Video Board",
          description:
            "The largest of all the interior boards, the South Video Board is a massive 48’H x 253’L.  Ideal for presentations, sponsor recognition, live feeds, and so much more – this board is the WOW factor.",
        },
        {
          image: "Experiences/Branding/south-video-board.jpg",
          title: "South Video Board",
          description:
            "The South Video Board can seamlessly tie into the entertainment and content that is showcased in any event space.",
        },
        {
          image: "Experiences/Branding/south-video-board-3.jpg",
          title: "South Video Board",
          description:
            "The South Video Board can seamlessly tie into the entertainment and content that is showcased in any event space.",
        },
      ],
      ribbonBoards: [
        {
          image: "Experiences/Branding/ribbon-board.jpg",
          title: "Upper and Lower Ribbon Boards",
          description:
            "Two layers of immersive Ribbon Boards line the entire venue and are a unique way to highlight attendee names, messaging, sponsors, and much more.",
        },
      ],
      marquee: [
        {
          image: "Experiences/Branding/mar.jpg",
          // title: 'Marquee',
          description:
            "Situated on the north side of the Stadium directly next to the main entrance of the building, this board is a memorable way to welcome your guests.",
        },
      ],
      mediaMesh: [
        {
          image: "Experiences/Branding/media-mesh-1.jpg",
          // title: 'Media Mesh',
          description:
            "At 78’H x 347’W and facing the Las Vegas Strip, the Media Mesh makes an undeniably powerful statement as guests arrive.",
        },
        {
          image: "Experiences/Branding/media-mesh-2.jpg",
          // title: 'Media Mesh',
          description:
            "Capture attention and create excitement by taking over the side of Allegiant Stadium with custom content.",
        },
      ],
      raiderettes: [
        {
          image: "Experiences/raiderettes.jpg",
          // title: 'Raiderettes',
          description:
            "Football's Fabulous Females, the Raiderettes, have graced the sidelines during Raiders home games since their formation in 1961. Create exciting and memorable moments for each guest by adding a Raiderette photo opportunity to any event.",
        },
      ],
      ppk: [
        {
          image: "Experiences/ppk/ppk-01.jpg",
          title: "Punt, Pass, and Kick",
          description:
            "Test your guest’s skills with a punt, pass, and kick experience featuring four themed stations designed to excite attendees and create connections. This activation will be the talk of the event and will provide guests with lasting stories to tell.",
        },
      ],
      imag: [
        {
          image: "Experiences/imag/imag-01.jpg",
          // title: 'IMAG',
          description:
            "Your guest’s name in lights! Build excitement and energy as you feature your guests and activations on the video boards. This experience is also ideal for speakers and will enhance any presentation or performance.",
        },
      ],
      rusher: [
        {
          image: "Experiences/rusher.jpg",
          // title: 'Rusher',
          description:
            "The Raider Rusher will add energy to any event by interacting with guests and electrifying the atmosphere.",
        },
      ],
      alumni: [
        {
          image: "Experiences/alumni.jpg",
          // title: 'Alumni',
          description:
            "Raider Alumni are a unique and exclusive addition to any event and provide exciting opportunities for guests to interact and take photos with legends.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#ExperiencesMobile {
  width: 100vw;
  min-height: 100vh;
  padding-top: 137px;
  background: url("~@/assets/images/MobileBG/Enhancements-Experiences-BG.jpg")
    center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 48%,
      rgba(0, 0, 0, 0.72) 88%
    );
  }
  .item {
    position: relative;
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 26px;
      color: #ffffff;
      letter-spacing: 1.62px;
      line-height: 25px;
      width: fit-content;
      margin-bottom: 20px;
      text-transform: uppercase;
      @media (max-width: 993px) {
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      .line {
        height: 2px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin-top: 20px;
      }
    }
    .dividerOne {
      width: 100%;
      height: 2px;
      background: #1a1a1a;
      margin-top: 40px;
    }
    .dividerTwo {
      width: 100%;
      height: 2px;
      background: #2c2c2c;
      margin-bottom: 40px;
    }
  }
  .tours {
    margin-bottom: 30px;
    height: 700px;
    @media (max-width: $md) {
      height: 600px;
    }
    .dividerOne {
      margin-top: 60px;
    }
  }
}
</style>
